import { $ } from './../helpers/qsa'

export default class {

    constructor(rootElement = document.body) {
        this.rootElement = rootElement

        this.config = [
            ['.fb-post, .fb-video', 'loadFacebook'],
            ['.instagram-media', 'loadInstagram'],
            ['.twitter-tweet', 'loadTwitter'],
        ]
    }

    mount() {
        this.config.forEach(([selector, callback]) => {
            // Set up observer
            const observer = new IntersectionObserver(entries => {
                this.handleInstersection(observer, entries, this[callback].bind(this))
            }, {
                rootMargin: '200px 0px 200px 0px'
            })

            // Attach first found element
            const el = $(selector, this.rootElement)

            if (el !== null) {
                observer.observe(el)
            }
        })
    }

    handleInstersection(observer, entries, callback) {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry

            if (isIntersecting) {
                callback()
                observer.unobserve(target)
            }
        })
    }

    loadFacebook() {
        if (window.FB) {
            FB.XFBML.parse()
        } else {
            this.injectScript('https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0', 'facebook-js')
        }
    }

    loadInstagram() {
        if (window.instgrm) {
            instgrm.Embeds.process()
        } else {
            this.injectScript('https://platform.instagram.com/en_US/embeds.js', 'instagram-js')
        }
    }

    loadTwitter() {
        if (window.twttr) {
            window.twttr.ready(() => twttr.widgets.load())
        } else {
            this.injectScript('https://platform.twitter.com/widgets.js', 'twitter-wjs')
        }
    }

    injectScript(src, id) {
        const script = document.createElement('script')

        script.src = src
        script.id = id

        document.body.appendChild(script)
    }

}
