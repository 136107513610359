import { $, $$ } from '../helpers/qsa'
import ResizeObserver from 'resize-observer-polyfill'

export default class {

    mount() {
        // Grid observer
        this.gridObserver = new ResizeObserver(this.handleGridResize.bind(this))
        $$('.card-grid').forEach(el => this.gridObserver.observe(el))

        // Large horizontal card observer
        this.lgHorizontalObserver = new ResizeObserver(this.handleHorizontalResize.bind(this))
        $$('.card--horizontal-lg').forEach(el => this.lgHorizontalObserver.observe(el))
    }

    handleGridResize(entries) {
        entries.forEach(entry => {
            const { target } = entry
            const switchTest = window.innerWidth < 600

            Array.from(target.children).forEach(card => {
                card.classList.toggle('card--horizontal', switchTest)
            })
        })
    }

    handleHorizontalResize(entries) {
        entries.forEach(entry => {
            const { target } = entry
            const switchTest = target.clientWidth > 540

            target.classList.toggle('card--horizontal', switchTest)
            target.classList.toggle('card--horizontal-lg', switchTest)
        })
    }

}
