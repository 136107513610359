import 'lazysizes'
import {
    Card,
    Embeds
} from './components'

const components = [
    new Card(),
    new Embeds()
]

components.forEach(component => component.mount())
